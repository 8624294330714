import type { Socket } from 'socket.io-client';
import {
  BalancesFilter,
  BlockFilter,
  CollectionFilter,
  ContractFilter,
  SubscriptionEvents,
  EventsFilter,
  ExtrinsicFilter,
  Room,
  AccountCurrentBalanceFilter,
} from '@unique-nft/common/types/subscriptions';
import {
  AccountCurrentBalanceData,
  BalancesData,
  BlockData,
  CollectionData,
  ContractLogData,
  EventData,
  ExtrinsicData,
  HasNextData,
  SubscribeStateData,
} from '../../types';

export { SubscriptionEvents } from '@unique-nft/common/types/subscriptions';

export class SocketClient {
  constructor(public readonly socket: Socket) {}

  on(
    event: SubscriptionEvents.SYSTEM,
    listener: (room: Room, data: any) => void,
  );
  on(
    event: SubscriptionEvents.BLOCKS,
    listener: (room: Room, data: BlockData) => void,
  );
  on(
    event: SubscriptionEvents.EXTRINSICS,
    listener: (room: Room, data: ExtrinsicData) => void,
  );
  on(
    event: SubscriptionEvents.EVENTS,
    listener: (room: Room, data: EventData) => void,
  );
  on(
    event: SubscriptionEvents.COLLECTIONS,
    listener: (room: Room, data: CollectionData) => void,
  );
  on(
    event: SubscriptionEvents.CONTRACT_LOGS,
    listener: (room: Room, data: ContractLogData) => void,
  );
  on(
    event: SubscriptionEvents.BALANCES,
    listener: (room: Room, data: BalancesData) => void,
  );
  on(
    event: SubscriptionEvents.ACCOUNT_CURRENT_BALANCE,
    listener: (room: Room, data: AccountCurrentBalanceData) => void,
  );
  on(
    event: SubscriptionEvents.SUBSCRIBE_STATE,
    listener: (room: Room, data: SubscribeStateData) => void,
  );
  on(
    event: SubscriptionEvents.HAS_NEXT,
    listener: (room: Room, data: HasNextData) => void,
  );
  on(event: string, listener) {
    this.socket.on(event, listener);
  }

  subscribeBlocks(filter?: BlockFilter) {
    this.socket.emit('subscribe:blocks', filter);
    return this;
  }

  unsubscribeBlocks(filter?: BlockFilter) {
    this.socket.emit('unsubscribe:blocks', filter);
    return this;
  }

  subscribeExtrinsics(filter?: ExtrinsicFilter) {
    this.socket.emit('subscribe:extrinsics', filter);
    return this;
  }

  unsubscribeExtrinsics(filter?: ExtrinsicFilter) {
    this.socket.emit('unsubscribe:extrinsics', filter);
    return this;
  }

  subscribeEvents(filter?: EventsFilter) {
    this.socket.emit('subscribe:events', filter);
    return this;
  }

  unsubscribeEvents(filter?: EventsFilter) {
    this.socket.emit('unsubscribe:events', filter);
    return this;
  }

  subscribeCollection(filter?: CollectionFilter) {
    this.socket.emit('subscribe:collections', filter);
    return this;
  }

  unsubscribeCollection(filter?: CollectionFilter) {
    this.socket.emit('unsubscribe:collections', filter);
    return this;
  }

  subscribeContract(filter?: ContractFilter) {
    this.socket.emit('subscribe:contracts', filter);
    return this;
  }

  unsubscribeContract(filter?: ContractFilter) {
    this.socket.emit('unsubscribe:contracts', filter);
    return this;
  }

  subscribeBalances(filter?: BalancesFilter) {
    this.socket.emit('subscribe:balances', filter);
    return this;
  }

  unsubscribeBalances(filter?: BalancesFilter) {
    this.socket.emit('unsubscribe:balances', filter);
    return this;
  }

  subscribeAccountCurrentBalance(filter?: AccountCurrentBalanceFilter) {
    this.socket.emit('subscribe:account:current:balance', filter);
    return this;
  }

  unsubscribeAccountCurrentBalance(filter?: AccountCurrentBalanceFilter) {
    this.socket.emit('unsubscribe:account:current:balance', filter);
    return this;
  }
}
