import { Section } from '../../common';
import {
  AbiItemDto,
  ContractCallArguments,
  ContractSendArguments,
  EvmCallResponseDto,
  EvmSendResultParsed,
  IBaseClient,
  IContract,
  IMutationEx,
  Method,
} from '../../types';
import { createContractSendMutation } from './mutation';

export class Contract extends Section implements IContract {
  readonly send: IMutationEx<ContractSendArguments, EvmSendResultParsed>;

  constructor(
    client: IBaseClient,
    readonly contractAddress: string,
    readonly abi: AbiItemDto[],
  ) {
    super(client, 'evm');

    this.send = createContractSendMutation(this);
  }

  public async call(args: ContractCallArguments): Promise<EvmCallResponseDto> {
    const response = await this.client.instance({
      method: Method.POST,
      baseURL: this.baseUrl,
      url: 'call',
      data: {
        contractAddress: this.contractAddress,
        abi: this.abi,
        ...args,
      },
    });
    return response.data;
  }
}
