import {
  EvmCallArguments,
  EvmContractExistsArguments,
  EvmContractExistsResponse,
} from '@unique-nft/common/types/evm';
import { Section } from '../../common';
import { IEvm, AbiItemDto } from '../../types';
import { Contract } from './contract';
import { createEvmSendMutation } from './mutation';

export class Evm extends Section implements IEvm {
  readonly send = createEvmSendMutation(this);

  readonly contractExists = (
    args: EvmContractExistsArguments,
  ): Promise<EvmContractExistsResponse> => this.query('contract-exists', args);

  call<R = any>(args: EvmCallArguments): Promise<R> {
    return this.postRequest('call', args);
  }

  public async contractConnect(
    contractAddress: string,
    abi: AbiItemDto[],
  ): Promise<Contract> {
    const { exists } = await this.contractExists({ contractAddress });
    if (!exists) {
      throw new Error('Contract is not exists');
    }
    return new Contract(this.client, contractAddress, abi);
  }
}
