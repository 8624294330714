import { IBaseClient, ISection, Method } from '../types';

export abstract class Section implements ISection {
  public readonly baseUrl: string;

  constructor(
    public readonly client: IBaseClient,
    public readonly path: string,
  ) {
    this.baseUrl = `${this.client.options.baseUrl}/${path}`;
  }

  async query<P, R>(url: string, params?: P): Promise<R> {
    const response = await this.client.instance({
      method: Method.GET,
      baseURL: this.baseUrl,
      url,
      params,
    });
    return response.data;
  }

  async postRequest<P, R, Q>(url: string, data?: P, params?: Q): Promise<R> {
    const response = await this.client.instance({
      method: Method.POST,
      baseURL: this.baseUrl,
      url,
      data,
      params,
    });
    return response.data;
  }
}
