import { Section } from '../common';
import {
  ChainPropertiesResponse,
  GetNonceQuery,
  GetNonceResponse,
  ICommon,
} from '../types';

export class Common extends Section implements ICommon {
  readonly chainProperties = (): Promise<ChainPropertiesResponse> =>
    this.query('chain-properties');

  readonly getNonce = (args: GetNonceQuery): Promise<GetNonceResponse> =>
    this.query('nonce', args);
}
