import { Section } from '../common';
import {
  TokenChildrenResponse,
  AccountTokensResponse,
  TokenPropertiesResponse,
  SetTokenPropertiesParsed,
  DeleteTokenPropertiesParsed,
  TransferTokenBody,
  TransferTokenParsed,
  TokenOwnerResponse,
  IToken,
  CreateTokenBody,
  CreateMultipleTokensBody,
  TokenId,
  TokenIdQuery,
  TokenByIdResponse,
  TokenParentResponse,
  TopmostTokenOwnerResponse,
  NestTokenBody,
  UnnestTokenBody,
  SetTokenPropertiesBody,
  DeleteTokenPropertiesBody,
  BurnTokenBody,
  BurnTokenParsed,
  TokenExistsResponse,
  ApproveTokenBody,
  ApproveTokenParsed,
  AllowanceResultResponse,
  AccountTokensQuery,
  AllowanceArgumentsQuery,
  IsBundleResponse,
  GetBundleResponse,
  Method,
  TokenBalanceQuery,
  TokenBalanceResponse,
} from '../types';
import { createMutationEx } from '../common/mutation';

export class Token extends Section implements IToken {
  readonly setProperties = createMutationEx<
    SetTokenPropertiesBody,
    SetTokenPropertiesParsed
  >(this.client, Method.POST, `${this.path}/properties`);

  readonly deleteProperties = createMutationEx<
    DeleteTokenPropertiesBody,
    DeleteTokenPropertiesParsed
  >(this.client, Method.DELETE, `${this.path}/properties`);

  readonly properties = (
    args: TokenIdQuery,
  ): Promise<TokenPropertiesResponse> => this.query('properties', args);

  readonly create = createMutationEx<CreateTokenBody, TokenId>(
    this.client,
    Method.POST,
    this.path,
  );

  readonly createMultiple = createMutationEx<
    CreateMultipleTokensBody,
    TokenId[]
  >(this.client, Method.POST, `${this.path}/create-multiple`);

  readonly accountTokens = (
    args: AccountTokensQuery,
  ): Promise<AccountTokensResponse> => this.query('account-tokens', args);

  readonly get = (args: TokenIdQuery): Promise<TokenByIdResponse> =>
    this.query('', args);

  readonly exists = (args: TokenIdQuery): Promise<TokenExistsResponse> =>
    this.query('exists', args);

  readonly children = (args: TokenIdQuery): Promise<TokenChildrenResponse> =>
    this.query('children', args);

  readonly parent = (args: TokenIdQuery): Promise<TokenParentResponse> =>
    this.query('parent', args);

  readonly owner = (args: TokenIdQuery): Promise<TokenOwnerResponse> =>
    this.query('owner', args);

  readonly topmostOwner = (
    args: TokenIdQuery,
  ): Promise<TopmostTokenOwnerResponse> => this.query('topmost-owner', args);

  readonly nest = createMutationEx<NestTokenBody, TokenId>(
    this.client,
    Method.POST,
    `${this.path}/nest`,
  );

  readonly unnest = createMutationEx<UnnestTokenBody, TokenId>(
    this.client,
    Method.POST,
    `${this.path}/unnest`,
  );

  readonly burn = createMutationEx<BurnTokenBody, BurnTokenParsed>(
    this.client,
    Method.DELETE,
    this.path,
  );

  readonly transfer = createMutationEx<TransferTokenBody, TransferTokenParsed>(
    this.client,
    Method.PATCH,
    `${this.path}/transfer`,
  );

  readonly approve = createMutationEx<ApproveTokenBody, ApproveTokenParsed>(
    this.client,
    Method.POST,
    `${this.path}/approve`,
  );

  readonly allowance = (
    args: AllowanceArgumentsQuery,
  ): Promise<AllowanceResultResponse> => this.query('allowance', args);

  readonly isBundle = (args: TokenIdQuery): Promise<IsBundleResponse> =>
    this.query('is-bundle', args);

  readonly getBundle = (args: TokenIdQuery): Promise<GetBundleResponse> =>
    this.query('get-bundle', args);

  readonly balance = (args: TokenBalanceQuery): Promise<TokenBalanceResponse> =>
    this.query('balance', args);
}
