import FormData from 'form-data';
import { Section } from '../common';
import {
  IIpfs,
  IpfsAddMultipleRequest,
  IpfsUploadMultipleRequest,
  IpfsUploadRequest,
  IpfsUploadResponse,
  Method,
  FileLike,
} from '../types';

const isFileLike = (input: FileLike | File): input is FileLike =>
  Object.hasOwnProperty.call(input, 'content');

export class Ipfs extends Section implements IIpfs {
  private headers = { 'Content-Type': `multipart/form-data` };

  async uploadFile({ file }: IpfsUploadRequest): Promise<IpfsUploadResponse> {
    const data = new FormData();
    data.append('file', file, 'file');

    const response = await this.client.instance({
      method: Method.POST,
      url: `${this.baseUrl}/upload-file`,
      data,
      headers: this.headers,
    });

    return response.data;
  }

  async uploadFiles({
    files,
  }: IpfsUploadMultipleRequest): Promise<IpfsUploadResponse> {
    const data = Ipfs.filesToFormData(files);

    const response = await this.client.instance({
      method: Method.POST,
      url: `${this.baseUrl}/upload-files`,
      data,
      headers: this.headers,
    });

    return response.data;
  }

  async addFiles({
    cid,
    files,
  }: IpfsAddMultipleRequest): Promise<IpfsUploadResponse> {
    const data = Ipfs.filesToFormData(files);
    data.append('cid', cid);

    const response = await this.client.instance({
      method: Method.PATCH,
      url: `${this.baseUrl}/add-files`,
      data,
      headers: this.headers,
    });

    return response.data;
  }

  async uploadZip({ file }: IpfsUploadRequest): Promise<IpfsUploadResponse> {
    const form = new FormData();
    form.append('file', file, 'file');

    const response = await this.client.instance({
      method: Method.POST,
      url: `${this.baseUrl}/upload-zip`,
      data: form,
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    });

    return response.data;
  }

  private static filesToFormData(files: Array<FileLike | File>): FormData {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append(
        'files',
        isFileLike(file) ? file.content : file,
        file.name,
      );
    });

    return formData;
  }
}
