import { Coder, Event } from 'abi-coder';

import {
  AbiItemDto,
  EvmEvent,
  EvmEvents,
  EvmUnknownEvent,
  ExtrinsicResultEvent,
} from '../../types';

export const parseEvmEvents = (
  events: ExtrinsicResultEvent[],
  abi: AbiItemDto[],
): EvmEvents => {
  const erc20Coder = new Coder(abi);
  const parsedEvents: EvmEvent[] = [];
  const unknownEvents: EvmUnknownEvent[] = [];

  events
    .filter((event) => event.section === 'evm' && event.method === 'Log')
    .forEach((event) => {
      const logData = event.data[0];

      const { topics, data } = logData;

      try {
        const decoded: Event = erc20Coder.decodeEvent(topics, data);

        parsedEvents.push({
          name: decoded.name,
          values: decoded.values,
        });
      } catch (err) {
        unknownEvents.push({
          topics,
          data,
        });
      }
    });

  return {
    parsedEvents,
    unknownEvents,
  };
};
