import { Section } from '../common';
import {
  ApiGetterParams,
  ApiRequestParams,
  ApiRequestBody,
  Method,
  IStateQueries,
} from '../types';

export class StateQueries extends Section implements IStateQueries {
  async get<T = any>(args: ApiGetterParams): Promise<T> {
    const { endpoint, module, method } = args;
    const url = `${endpoint}/${module}/${method}`;
    const response = await this.client.instance({
      method: Method.GET,
      baseURL: this.baseUrl,
      url,
    });
    return response.data;
  }

  async execute<T = any>(
    params: ApiRequestParams,
    body?: ApiRequestBody,
  ): Promise<T> {
    const { endpoint, module, method } = params;
    const url = `${endpoint}/${module}/${method}`;
    const response = await this.client.instance({
      method: Method.POST,
      baseURL: this.baseUrl,
      url,
      data: body || {},
    });
    return response.data;
  }
}
