import { Section } from '../common';
import {
  CreateCollectionParsed,
  CreateERC721CollectionBody,
  CreateERC721TokenBody,
  IERC721,
  Method,
  TokenId,
} from '../types';
import { createMutationEx } from '../common/mutation';

export class ERC721 extends Section implements IERC721 {
  readonly createCollection = createMutationEx<
    CreateERC721CollectionBody,
    CreateCollectionParsed
  >(this.client, Method.POST, `${this.path}/collection`);

  readonly createToken = createMutationEx<CreateERC721TokenBody, TokenId>(
    this.client,
    Method.POST,
    `${this.path}/token`,
  );
}
