import { Section } from '../common';
import {
  CollectionIdQuery,
  CollectionInfoWithSchemaResponse,
  IRefungible,
  Method,
  TokenBalanceQuery,
  TokenBalanceResponse,
  CreateRefungibleCollectionRequest,
  CreateRefungibleTokenRequest,
  TransferRefungibleTokenRequest,
  ApproveRefungibleTokenRequest,
  AllowanceArgumentsQuery,
  AllowanceRefungibleTokenResponse,
  RepartitionTokenRequest,
  TotalPiecesResponse,
  TokenId,
  CreateCollectionParsed,
  CreatRefungibleTokenParsed,
  TransferRefungibleTokenParsed,
  ApproveRefungibleTokenParsed,
  RepartitionTokenParsed,
  AccountRefungibleTokenQuery,
  AccountRefungibleTokensResponse,
  BurnRefungibleBody,
  BurnRefungibleParsed,
} from '../types';
import { createMutationEx } from '../common/mutation';

export class Refungible extends Section implements IRefungible {
  readonly getCollection = (
    args: CollectionIdQuery,
  ): Promise<CollectionInfoWithSchemaResponse> =>
    this.query('collection', args);

  readonly burn = createMutationEx<BurnRefungibleBody, BurnRefungibleParsed>(
    this.client,
    Method.DELETE,
    this.path,
  );

  readonly getBalance = (
    args: TokenBalanceQuery,
  ): Promise<TokenBalanceResponse> => this.query('tokens/balance', args);

  readonly createCollection = createMutationEx<
    CreateRefungibleCollectionRequest,
    CreateCollectionParsed
  >(this.client, Method.POST, `${this.path}/collection`);

  readonly createToken = createMutationEx<
    CreateRefungibleTokenRequest,
    CreatRefungibleTokenParsed
  >(this.client, Method.POST, `${this.path}/tokens`);

  readonly transferToken = createMutationEx<
    TransferRefungibleTokenRequest,
    TransferRefungibleTokenParsed
  >(this.client, Method.POST, `${this.path}/tokens/transfer`);

  readonly approveToken = createMutationEx<
    ApproveRefungibleTokenRequest,
    ApproveRefungibleTokenParsed
  >(this.client, Method.POST, `${this.path}/tokens/approve`);

  readonly allowanceToken = (
    args: AllowanceArgumentsQuery,
  ): Promise<AllowanceRefungibleTokenResponse> =>
    this.query('tokens/allowance', args);

  readonly repartitionToken = createMutationEx<
    RepartitionTokenRequest,
    RepartitionTokenParsed
  >(this.client, Method.POST, `${this.path}/tokens/repartition`);

  readonly totalPieces = (args: TokenId): Promise<TotalPiecesResponse> =>
    this.query('tokens/total-pieces', args);

  readonly accountTokens = (
    args: AccountRefungibleTokenQuery,
  ): Promise<AccountRefungibleTokensResponse> =>
    this.query('tokens/account-tokens', args);
}
