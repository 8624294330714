type ChainConfig = {
  websocketUrl: string;
  rpcUrl: string;
  restUrl: string;
  ss58Prefix: number;
  currency: {
    symbol: string;
    decimals: number;
  };
  logo: {
    color: `#${string}`;
    ipfsCid: string;
  };
};

export enum Chains {
  opal = 'opal',
  sapphire = 'sapphire',
  quartz = 'quartz',
  unique = 'unique',
}

export const CHAIN_CONFIG: Record<`${Chains}`, ChainConfig> = {
  opal: {
    websocketUrl: 'wss://ws-opal.unique.network',
    rpcUrl: 'https://rpc-opal.unique.network',
    restUrl: 'https://rest.unique.network/opal/v1',
    ss58Prefix: 42,
    currency: {
      symbol: 'OPL',
      decimals: 18,
    },
    logo: {
      color: '#0CB6B8',
      ipfsCid: 'QmYJDpmWyjDa3H6BxweFmQXk4fU8b1GU7M9EqYcaUNvXzc',
    },
  },
  sapphire: {
    websocketUrl: 'wss://ws-sapphire.unique.network',
    ss58Prefix: 8883,
    rpcUrl: 'https://rpc-sapphire.unique.network',
    restUrl: 'https://rest.unique.network/sapphire/v1',
    currency: {
      symbol: 'QTZ',
      decimals: 18,
    },
    logo: {
      color: '#5D59FF',
      ipfsCid: 'Qmd1PGt4cDRjFbh4ihP5QKEd4XQVwN1MkebYKdF56V74pf',
    },
  },
  quartz: {
    websocketUrl: 'wss://ws-quartz.unique.network',
    ss58Prefix: 255,
    rpcUrl: 'https://rpc-quartz.unique.network',
    restUrl: 'https://rest.unique.network/quartz/v1',
    currency: {
      symbol: 'QTZ',
      decimals: 18,
    },
    logo: {
      color: '#FF4D6A',
      ipfsCid: 'QmaGPdccULQEFcCGxzstnmE8THfac2kSiGwvWRAiaRq4dp',
    },
  },
  unique: {
    websocketUrl: 'wss://ws.unique.network',
    ss58Prefix: 7391,
    rpcUrl: 'https://rpc.unique.network',
    restUrl: 'https://rest.unique.network/unique/v1',
    currency: {
      symbol: 'UNQ',
      decimals: 18,
    },
    logo: {
      color: '#00BFFF',
      ipfsCid: 'QmbJ7CGZ2GxWMp7s6jy71UGzRsMe4w3KANKXDAExYWdaFR',
    },
  },
};
