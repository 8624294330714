interface RestError {
  code: string;
  name: string;
  message: string;
  details?: unknown;
}

export class ApiError extends Error implements RestError {
  public readonly code: string;

  public readonly details: unknown;

  constructor(responseError: RestError) {
    super(responseError.message);
    this.code = responseError.code;
    this.name = responseError.name;
    this.details = responseError.details;
  }
}
