import { Section } from '../common';
import {
  BalanceTransferBody,
  BalanceTransferParsed,
  IFungible,
  FungibleCollectionInfoDto,
  CreateFungibleCollectionRequest,
  CreateCollectionParsed,
  BalanceResponse,
  AddTokensResultDto,
  TransferTokensArgsDto,
  TransferTokensResultDto,
  AddTokensArgsDto,
  CollectionIdQuery,
  GetFungibleBalanceArgsRequest,
  Method,
  ApproveFungibleTokensArgs,
  AllowanceFungibleTokensArgumentsDto,
  ApproveFungibleTokensParsed,
  BurnFungibleBody,
  BurnFungibleParsed,
  GetTotalPiecesArgumentsDto,
  GetTotalPiecesResultDto,
} from '../types';
import { createMutationEx } from '../common/mutation';

export class Fungible extends Section implements IFungible {
  readonly transfer = createMutationEx<
    BalanceTransferBody,
    BalanceTransferParsed
  >(this.client, Method.POST, `${this.path}/transfer`);

  readonly getCollection = (
    args: CollectionIdQuery,
  ): Promise<FungibleCollectionInfoDto> => this.query('collection', args);

  readonly burn = createMutationEx<BurnFungibleBody, BurnFungibleParsed>(
    this.client,
    Method.DELETE,
    this.path,
  );

  readonly createCollection = createMutationEx<
    CreateFungibleCollectionRequest,
    CreateCollectionParsed
  >(this.client, Method.POST, `${this.path}/collection`);

  readonly getBalance = (
    args: GetFungibleBalanceArgsRequest,
  ): Promise<BalanceResponse> => this.query('balance', args);

  readonly addTokens = createMutationEx<AddTokensArgsDto, AddTokensResultDto>(
    this.client,
    Method.POST,
    `${this.path}/tokens`,
  );

  readonly transferTokens = createMutationEx<
    TransferTokensArgsDto,
    TransferTokensResultDto
  >(this.client, Method.POST, `${this.path}/tokens/transfer`);

  readonly approveTokens = createMutationEx<
    ApproveFungibleTokensArgs,
    ApproveFungibleTokensParsed
  >(this.client, Method.POST, `${this.path}/tokens/approve`);

  readonly allowanceTokens = (
    args: AllowanceFungibleTokensArgumentsDto,
  ): Promise<BalanceResponse> => this.query('tokens/allowance', args);

  readonly totalPieces = (
    args: GetTotalPiecesArgumentsDto,
  ): Promise<GetTotalPiecesResultDto> => this.query('total-pieces', args);
}
